import { useEffect, useRef, useState } from "react";
import { InfoIcon } from "../../assets/info";
import { cn } from "../../lib/cn";

type Props = {
	suggestion: string; // | React.ReactNode
	// children: React.ReactNode;
};

export function Information({ suggestion }: Props) {
	const [isVisible, setIsVisible] = useState(false);
	const visibilityRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		return () => clearTimeout(visibilityRef.current as NodeJS.Timeout);
	}, []);

	return (
		<div
			className="relative w-min"
			onMouseEnter={() => setIsVisible(true)}
			onMouseLeave={() => {
				if (visibilityRef.current) {
					clearTimeout(visibilityRef.current as NodeJS.Timeout);
				}

				visibilityRef.current = setTimeout(() => {
					setIsVisible(false);

					visibilityRef.current = null;
				}, 150);
			}}
		>
			<span className="flex space-x-1 items-center cursor-pointer">
				<InfoIcon className="w-3 h-3 fill-neutral-500" />
			</span>
			<div
				className={cn(
					"absolute bottom-6 left-1/2 -translate-x-1/2 px-2 py-1 w-min bg-white border border-neutral-200 shadow-sm rounded-lg transition-all duration-150 ease-out",
					isVisible
						? "opacity-100 scale-100"
						: "opacity-0 scale-95 pointer-events-none",
				)}
			>
				<div className="flex items-center w-[220px]">
					<span className="text-xs text-neutral-600 text-center">
						{suggestion}
					</span>
				</div>
			</div>
		</div>
	);
}
