import { getAccessToken } from "@privy-io/react-auth";
import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";

dayjs.extend(utc);

async function getTimeseries() {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	const searchParams = new URLSearchParams();

	const from = dayjs().subtract(3, "month").startOf("day");
	const to = dayjs().endOf("day");

	searchParams.set("from", from.toISOString());
	searchParams.set("to", to.toISOString());

	const payments = await fetcher(
		`${
			import.meta.env.VITE_API_URL
		}/dashboard/timeseries?${searchParams.toString()}`,
		z
			.object({
				amount: z.number(),
				receivedAmount: z.number(),
				createdAt: z.coerce.date(),
			})
			.array(),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);

	if (!payments) {
		return [];
	}

	const dataPoints = dayjs(to).diff(from, "day") + 1;

	const data: {
		date: Date;
		value: number;
	}[] = [];

	for (let i = 0; i < dataPoints; i++) {
		const date = dayjs.utc(to).subtract(i, "day").startOf("day").toDate();

		const paymentsInInterval = payments.filter((event) =>
			dayjs(event.createdAt).isSame(date, "day"),
		);

		const value = paymentsInInterval
			.map(({ receivedAmount }) => receivedAmount)
			.reduce((acc, amount) => acc + amount, 0);

		data.push({
			date,
			value,
		});
	}

	return data.reverse();
}

export function timeseriesOptions() {
	return queryOptions({
		queryKey: ["timeseries"],
		queryFn: () => getTimeseries(),
		staleTime: 5 * 1000,
	});
}

export function useTimeseries() {
	const { data, ...query } = useQuery(timeseriesOptions());

	return {
		timeseries: data || [],
		...query,
	};
}

export function useSuspenseTimeseries() {
	const { data, ...query } = useSuspenseQuery(timeseriesOptions());

	return {
		timeseries: data || [],
		...query,
	};
}
